/* eslint-disable no-console */
import {
  ErrorType,
  ThrownException,
  isThrownException,
  GRPC_REQUEST_FAILED,
  ChainCosmosErrorCode,
  GrpcUnaryRequestException
} from '@injectivelabs/exceptions'
import { StatusCodes } from 'http-status-codes'
import { BUGSNAG_KEY } from '@shared/utils/constant'
import { defineNuxtPlugin } from '#imports'
import { IS_PRODUCTION } from '@/app/utils/constant'
import { Modal } from '@/types'

/**
 * As we conditionally include the nuxt-bugsnag module
 * the type of it can be undefined
 **/
declare let useBugsnag: () => any

const reportToUser = (error: ThrownException) => {
  const toast = useToast()

  // Timedout requests happening in the background should not be reported to the user
  if (
    error.type === ErrorType.HttpRequest &&
    error.code === StatusCodes.REQUEST_TOO_LONG
  ) {
    return
  }

  if (
    error instanceof GrpcUnaryRequestException &&
    error.contextCode === GRPC_REQUEST_FAILED
  ) {
    toast.add({
      title: 'The product is experiencing higher than usual demand',
      description:
        'Hang tight, engineers are doing their best to improve the performance and efficiency.',
      color: 'red',
      icon: 'bxs:error'
    })

    return
  }

  if (error.message === error.originalMessage) {
    toast.add({
      title: error.message,
      description: error.originalMessage,
      color: 'red',
      icon: 'bxs:error',
      actions:
        error.context && error.context.startsWith('https')
          ? [
              {
                label: 'Try Here',
                click: () => window.open(error.context, '_blank')
              }
            ]
          : []
    })

    return
  }

  toast.add({
    title: error.message,
    description: error.originalMessage,
    color: 'red',
    icon: 'bxs:error'
  })
}

const reportToBugSnag = (error: ThrownException) => {
  if (!IS_PRODUCTION) {
    console.warn(error.toCompactError().message)
    console.error(error)

    return
  }

  if ([ErrorType.Unspecified, ErrorType.WalletError].includes(error.type)) {
    console.warn(error.toCompactError().message)
    console.error(error)

    return
  }

  if (BUGSNAG_KEY) {
    useBugsnag().notify(error, (event: any) => {
      event.errors.forEach((e: any) => {
        e.errorClass = error.errorClass || error.name || error.constructor.name
      })

      if (useSharedWalletStore().isUserConnected) {
        event.setUser(useSharedWalletStore().injectiveAddress)
      }

      event.addMetadata('error-context', error.toObject())
    })
  }
}

const reportUnknownErrorToBugsnag = (error: Error) => {
  if (!IS_PRODUCTION) {
    console.error({ error, stack: error.stack })
  }

  const newError = new Error(
    `The ${error.message} is not handled as an Exception - ${error.stack}`
  )

  console.warn(newError.message, newError.stack)

  if (BUGSNAG_KEY) {
    useBugsnag().notify(newError)
  }
}

const handleInsufficientGas = (error: ThrownException) => {
  const modalStore = useSharedModalStore()

  if (error.contextCode !== ChainCosmosErrorCode.ErrInsufficientFee) {
    return
  }

  modalStore.openModal(Modal.InsufficientInjForGas)
}

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()

  nuxtApp.vueApp.config.errorHandler = (error, context) => {
    console.log(error, context)
  }

  router.onError((error, to) => {
    if (error.message.includes('Failed to fetch dynamically imported module')) {
      window.location.href = to.fullPath
    }
  })

  window.onunhandledrejection = function (event: PromiseRejectionEvent) {
    const error = event.reason

    if (!IS_PRODUCTION) {
      return
    }

    if (!isThrownException(error)) {
      reportUnknownErrorToBugsnag(error)
    } else {
      reportToBugSnag(error)
    }
  }

  const errorHandler = (error: ThrownException) => {
    if (!isThrownException(error)) {
      return reportUnknownErrorToBugsnag(error)
    }

    reportToUser(error)

    if (IS_PRODUCTION) {
      reportToBugSnag(error)
    }

    console.warn(error.toObject())

    handleInsufficientGas(error)
  }

  return {
    provide: {
      onError: errorHandler
    }
  }
})
