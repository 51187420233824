export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"og:url","property":"og:url","content":"http://localhost"},{"hid":"keywords","name":"keywords","content":"injective, injective staking, injective governance, injective wallet, injective station, staking, governance, insurance funds, injective protocol"},{"hid":"description","name":"description","content":"A unified interface for Injective staking, governance, insurance funds and wallets"},{"hid":"author","name":"author","content":"Injective"},{"hid":"og:type","property":"og:type","content":"exchange"},{"hid":"og:image","property":"og:image","content":"http://localhost/images/share-hub.jpg"},{"hid":"og:description","property":"og:description","content":"A unified interface for Injective staking, governance, insurance funds and wallets"},{"hid":"twitter:card","property":"twitter:card","content":"summary_large_image"},{"hid":"twitter:site","property":"twitter:site","content":"@Injective_"},{"name":"twitter:image","content":"http://localhost/images/share-hub.jpg"},{"hid":"twitter:creator","property":"twitter:creator","content":"@Injective_"},{"hid":"twitter:description","property":"twitter:description","content":"A unified interface for Injective staking, governance, insurance funds and wallets"},{"hid":"twitter:title","property":"twitter:title","content":"Injective Hub"},{"hid":"og:title","property":"og:title","content":"Injective Hub"},{"hid":"og:site_name","property":"og:site_name","content":"Injective Hub"},{"hid":"title","property":"title","content":"Injective Hub"}],"link":[{"rel":"icon","type":"image/png","href":"/favicon.png"},{"rel":"shortcut icon","type":"image/png","href":"/favicon.png"},{"rel":"apple-touch-icon","type":"image/png","href":"/favicon.png"}],"style":[],"script":[{"innerHTML":"var exports = {}"}],"noscript":[],"title":"Injective Hub","htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"