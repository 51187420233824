import { TimeDuration } from '@shared/types'
import home from './en/home'
import terms from './en/terms'
import wallet from './en/wallet'
import auction from './en/auction'
import staking from './en/staking'
import proposal from './en/proposal'
import governance from './en/governance'
import transaction from './en/transaction'
import tokenFactory from './en/tokenFactory'
import { DappCategory, I18nMessageFunction } from '@/types'

export default {
  ...home,
  ...terms,
  ...wallet,
  ...auction,
  ...staking,
  ...proposal,
  ...governance,
  ...transaction,
  ...tokenFactory,

  title: 'Hub',
  getStartedOnInjective: 'Get Started on Injective',
  getStartedOnInjectiveDescription:
    'Step by step guide to start your journey on Injective',

  nav: {
    apps: 'Apps',
    home: 'Home',
    stake: 'Stake',
    wallet: 'Wallet',
    bridge: 'Bridge',
    auction: 'Auction',
    explorer: 'Explorer',
    ecosystem: 'Ecosystem',
    governance: 'Governance',
    essentials: 'Essentials',
    getStarted: 'Get Started',
    insuranceFunds: 'Insurance Funds'
  },

  networks: {
    ethereumPeggyNetwork: 'Ethereum Peggy'
  },

  common: {
    to: 'To',
    day: 'Day',
    max: 'Max',
    via: 'via',
    copy: 'Copy',
    days: 'Days',
    more: 'More',
    from: 'From',
    time: 'Time',
    price: 'Price',
    token: 'Token',
    cancel: 'Cancel',
    amount: 'Amount',
    status: 'Status',
    search: 'Search',
    confirm: 'Confirm',
    details: 'Details',
    success: 'Success',
    filters: 'Filters',
    staking: 'Staking',
    currency: 'Currency',
    required: 'Required',
    viewMore: 'View More',
    showMore: 'Show More',
    attention: 'Attention',
    available: 'Available',
    estValue: 'Est. Value',
    learnMore: 'Learn More',
    getStarted: 'Get Started',
    viewDetails: 'View Details',
    selectToken: 'Select Token',
    connectWallet: 'Connect Wallet',
    viewTransaction: 'View Transaction',
    empty: 'There are no results found',
    stakingRewardsApy: 'Staking Rewards APY',
    insufficientBalance: 'Insufficient Balance',
    injAmountWithFeeBuffer: `Please note that the maximum amount you can use is already calculated by Max, taking into account the small amount of INJ required to cover transaction fees.`
  },

  time: {
    [TimeDuration.Day]: 'days',
    [TimeDuration.Hour]: 'hours',
    [TimeDuration.Minute]: 'mins',
    [TimeDuration.Second]: 'sec'
  },

  input: {
    selectToken: 'Select Token'
  },

  devMode: {
    devMode: 'Dev Mode',
    connectWithAddress: 'Connect with address',
    connect: 'Connect',
    enterInjectiveAddress: 'Enter your injective address',
    txTimeout: 'Tx Timeout',
    setTxTimeout: 'Set Tx Timeout',
    success: 'Success'
  },

  georestricted: {
    description: 'Hub is not available in your region.'
  },

  pagination: {
    description: ({ named }: I18nMessageFunction) =>
      `Showing ${named('from')} - ${named('to')} out of ${named('totalCount')}`
  },

  command: {
    getStarted: 'Get Started',
    getStartedSuffix: 'Get started with Injective',
    wallet: 'Wallet',
    walletSuffix: 'Manage your assets',
    walletTransactions: 'Wallet Transactions',
    walletTransactionsSuffix: 'View your transactions',
    staking: 'Staking',
    stakingSuffix: 'Stake INJ tokens to earn rewards',
    myStaking: 'My Staking',
    myStakingSuffix: 'View your staking positions',
    governance: 'Governance',
    governanceSuffix: 'Participate in governance',
    auction: 'Auction',
    auctionSuffix: 'Participate in auctions'
  },

  ecosystem: {
    title: 'Discover the ecosystem',
    description:
      'Injective has built the largest and fastest growing Web3 financial ecosystem. Explore the global network of dApps, validators, builders and partners working together to create a truly free and inclusive financial world.',
    categories: {
      [DappCategory.Dex]: 'DEX',
      [DappCategory.DeFi]: 'DeFi',
      [DappCategory.Tools]: 'Tools',
      [DappCategory.NFTFi]: 'NFTFi',
      [DappCategory.SocialFi]: 'SocialFi',
      [DappCategory.AllCategory]: 'All Categories'
    }
  }
}
