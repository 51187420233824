<template>
  <svg
    viewBox="-1.4 5 30 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="logo-icon"
  >
    <path
      d="M3.464 11.003c.167-.215.344-.421.521-.628.008-.01.026-.012.034-.022.016-.02.042-.033.058-.053l.016-.02c.123-.117.254-.244.406-.355a6.014 6.014 0 011.677-.94c1.88-.674 3.972-.258 5.613 1.326 2.29 2.195 2.085 5.733.257 8.084-2.312 3.504-6.283 8.394-.784 12.774.989.788 1.722 1.437 4.836 2.357-2.036.384-3.925.265-6.027-.284-1.487-.859-3.825-2.696-4.62-5.178-1.203-3.763 2.116-9.389 3.72-11.555 2.202-3-1.361-6.245-3.984-2.621-1.372 1.888-3.77 7.233-2.937 11.197.487 2.248 1.137 3.887 3.713 6.138a12.182 12.182 0 01-1.391-.981c-5.986-5.7-5.291-14.514-1.108-19.239z"
      :fill="`url(#logo-inj-mini)`"
    ></path>
    <path
      d="M23.839 28.997c-.167.215-.344.421-.521.628-.008.01-.026.012-.034.022-.016.02-.042.033-.058.053l-.016.02c-.123.117-.254.244-.406.355a6.013 6.013 0 01-1.677.94c-1.88.674-3.972.258-5.613-1.326-2.29-2.195-2.085-5.733-.257-8.084 2.311-3.504 6.283-8.394.784-12.774-.989-.788-1.722-1.437-4.837-2.357 2.037-.384 3.926-.265 6.028.284 1.487.859 3.825 2.696 4.62 5.178 1.203 3.763-2.116 9.389-3.72 11.555-2.202 3 1.361 6.245 3.984 2.621 1.372-1.888 3.77-7.233 2.937-11.197-.488-2.248-1.137-3.887-3.713-6.138.477.288.941.615 1.391.981 5.985 5.7 5.291 14.514 1.108 19.239z"
      :fill="`url(#logo-inj-mini-1)`"
    ></path>
    <defs>
      <linearGradient
        :id="`logo-inj-mini`"
        x1=".203"
        y1="20"
        x2="27.1"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0082FA"></stop>
        <stop offset="1" stop-color="#00F2FE"></stop>
      </linearGradient>
      <linearGradient
        :id="`logo-inj-mini-1`"
        x1=".203"
        y1="20"
        x2="27.1"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0082FA"></stop>
        <stop offset="1" stop-color="#00F2FE"></stop>
      </linearGradient>
    </defs>
  </svg>
</template>
