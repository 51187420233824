export enum TokenFactorySupplyField {
  Amount = 'amount'
}

export enum TokenFactoryDenomField {
  SubDenom = 'subdenom',
  Name = 'name',
  Symbol = 'symbol'
}

export enum TokenFactoryMetadataField {
  URI = 'uri',
  Name = 'name',
  Symbol = 'symbol',
  Display = 'display',
  Exponent = 'exponent',
  Description = 'description'
}

export enum AuctionPoolField {
  Denom = 'denom',
  Amount = 'amount'
}

export enum SendFormField {
  Memo = 'memo',
  Amount = 'amount',
  InjAddress = 'injAddress'
}

export enum ProposalField {
  Title = 'title',
  Deposit = 'deposit',
  Description = 'description',
  ProposalType = 'proposalType'
}

export enum MarketProposalField {
  Ticker = 'ticker',
  BaseDenom = 'baseDenom',
  QuoteDenom = 'quoteDenom',
  MinNotional = 'minNotional',
  MakerFeeRate = 'makerFeeRate',
  TakerFeeRate = 'takerFeeRate',
  MinPriceTickSize = 'minPriceTickSize',
  MinQuantityTickSize = 'minQuantityTickSize'
}

export enum DerivativeProposalField {
  OracleBase = 'oracleBase',
  OracleType = 'oracleType',
  OracleQuote = 'oracleQuote',
  OracleScaleFactor = 'oracleScaleFactor',
  InitialMarginRatio = 'initialMarginRatio',
  MaintenanceMarginRatio = 'maintenanceMarginRatio'
}

export type AuctionPoolForm = {
  [AuctionPoolField.Denom]: string
  [AuctionPoolField.Amount]: string
}

export type TokenFactorySupplyForm = {
  [TokenFactorySupplyField.Amount]: string
}

export type TokenFactoryDenomForm = {
  [TokenFactoryDenomField.SubDenom]: string
}

export type TokenFactoryMetadataForm = {
  [TokenFactoryMetadataField.URI]: string
  [TokenFactoryMetadataField.Name]: string
  [TokenFactoryMetadataField.Symbol]: string
  [TokenFactoryMetadataField.Display]: string
  [TokenFactoryMetadataField.Exponent]: string
  [TokenFactoryMetadataField.Description]: string
}

export type SendForm = {
  [SendFormField.Memo]: string
  [SendFormField.Amount]: string
  [SendFormField.InjAddress]: string
}

export type DerivativeProposalForm = Record<
  ProposalField | MarketProposalField | DerivativeProposalField,
  any
>
export type TextProposalForm = Record<ProposalField, any>
export type SpotProposalForm = Record<ProposalField | MarketProposalField, any>
