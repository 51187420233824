export enum MixpanelEvent {
  Login = 'Login',
  Claim = 'Claim',
  Delegate = 'Delegate',
  Redelegate = 'Re-delegate',
  Unbond = 'Unbond',
  Vote = 'Vote',
  BidCount = 'Bid Count',
  PlaceBid = 'Place bid',
  ClaimAll = 'Claim All',
  ConnectClicked = 'Connect Clicked',
  WalletSelected = 'Wallet Selected',
  DarkThemeToggled = 'Dark Theme Toggled',
  KadoBannerClicked = 'Kado Banner Clicked'
}

export enum MixpanelCounter {
  Vote = 'Vote',
  Claim = 'Claim',
  Unbond = 'Unbond',
  Delegate = 'Delegate',
  ClaimAll = 'Claim All',
  BidCount = 'Bid Count',
  Redelegate = 'Re-delegate',
  ConnectClicked = 'Connect Clicked',
  KadoBannerClicked = 'Kado Banner Clicked'
}
