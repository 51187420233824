<script lang="ts" setup>
import { Modal } from '@/types'

const modalStore = useSharedModalStore()

const props = withDefaults(
  defineProps<{
    isSm?: boolean
    isLg?: boolean
    isMd?: boolean
    isXl?: boolean
    isXxl?: boolean
    modal?: Modal
    isOpen?: boolean
    isDense?: boolean
    contentClass?: string
    isHideDefaultHeader?: boolean
  }>(),
  {
    modal: undefined,
    contentClass: ''
  }
)

const emit = defineEmits<{
  'modal:closed': []
}>()

const showModal = computed(
  () => props.isOpen || (props.modal && modalStore.modals[props.modal])
)

const sizeClasses = computed(() => {
  if (props.isSm) {
    return ['max-w-sm xs:max-w-[448px]']
  }

  if (props.isMd) {
    return ['max-w-sm xs:max-w-[468px]']
  }

  if (props.isLg) {
    return ['max-w-sm xs:max-w-[480px]']
  }

  if (props.isXl) {
    return ['max-w-sm xs:max-w-[760px]']
  }

  if (props.isXxl) {
    return ['max-w-sm xs:max-w-[1024px]']
  }

  return ['max-w-sm sm:max-w-lg lg:max-w-xl']
})

const paddingClasses = computed(() => {
  if (props.isDense) {
    return
  }

  if (props.isSm) {
    return 'p-6'
  }

  if (props.isLg) {
    return 'p-8'
  }

  return 'p-6'
})

function onClose() {
  emit('modal:closed')

  if (props.modal) {
    modalStore.closeModal(props.modal)
  }
}
</script>

<template>
  <Transition name="modal" appear>
    <SharedModalWrapper
      v-if="showModal"
      class="shadow-card bg-brand-850 border border-gray-400 mx-auto rounded-2xl w-full"
      wrapper-class="backdrop-filter backdrop-blur bg-gray-975/90  max-sm:z-40"
      :class="[sizeClasses, paddingClasses, contentClass]"
      :ignore="['.v-popper__popper']"
      @modal:closed="onClose"
    >
      <template #default="{ close }">
        <div class="flex flex-col">
          <div v-if="!isHideDefaultHeader" class="block w-full">
            <div class="flex items-center justify-between">
              <div
                class="text-xs font-semibold uppercase tracking-wider text-white"
              >
                <slot name="title" />
              </div>
              <button
                type="button"
                class="hover:text-primary-500 rounded-md bg-transparent text-gray-200"
                @click="close"
              >
                <SharedIcon name="close" class="h-4 w-4" />
              </button>
            </div>
          </div>
          <slot name="header" :close="close" />
          <div>
            <slot />
          </div>
          <slot name="footer" :close="close" />
        </div>
      </template>
    </SharedModalWrapper>
  </Transition>
</template>

<style scoped>
.modal-enter-from,
.modal-leave-to {
  @apply opacity-0;
}

.modal-leave-active .modal-container {
  transition: 300ms cubic-bezier(0.4, 0, 1, 1);
  transform: scale(0.9);
}
</style>
