import { intervalToDuration } from 'date-fns'
import { ENDPOINTS } from '@shared/utils/constant'
import { sharedTokenClient } from '@shared/Service'
import { BigNumberInBase } from '@injectivelabs/utils'
import { TokenType, TokenSource, TokenStatic } from '@injectivelabs/sdk-ts'
import { tokenFactoryStatic } from '@/app/Service'
import { VOTING_POWER_PERCENTAGE } from '@/app/utils/constant'
import { UiSingleProposal, ValidatorWithUptimeDetails } from '@/types'

export const getUniqueListBy = (arr: any[], key = 'display') => {
  return [...new Map(arr.map((item) => [item[key], item])).values()]
}

export const getExactDecimalsFromNumber = (number: number | string): number => {
  if (!number.toString().includes('.')) {
    return 0
  }

  if (Number(number) % 1 === 0) {
    return 0
  }

  const [, decimals] = number.toString().split('.')

  if (!decimals) {
    return 0
  }

  return decimals.length
}

export const formatDurationFromSeconds = ({
  duration,
  fullSuffix
}: {
  duration: number
  fullSuffix?: boolean
}): string => {
  const { days, hours, minutes, seconds } = intervalToDuration({
    start: 0,
    end: duration * 1000
  })

  const suffix = fullSuffix
    ? ['day', 'hour', 'min', 'sec']
    : ['d', 'h', 'm', 's']
  let removeLeadingZeroDuration = true

  return (
    [days, hours, minutes, seconds]
      .map((duration, index) => {
        if (duration !== 0) {
          removeLeadingZeroDuration = false
        }

        if (fullSuffix) {
          const durationSuffix = duration && duration > 1 ? 's' : ''

          return removeLeadingZeroDuration && duration === 0
            ? undefined
            : `${duration} ${suffix[index]}${durationSuffix}`
        }

        return removeLeadingZeroDuration && duration === 0
          ? undefined
          : `${duration}${suffix[index]}`
      })
      .filter((duration) => duration)
      .join(' ')
      // remove tailing 0s duration
      .replace(' 0h 0m 0s', '')
      .replace(' 0m 0s', '')
      .replace(' 0s', '')
      .replace(' 0 hour 0 minute 0 second', '')
      .replace(' 0 minute 0 second', '')
      .replace(' 0 second', '')
  )
}

export const convertTimestampToMilliseconds = (timestamp: number) => {
  if (timestamp.toString().length > 13) {
    return parseInt(timestamp.toString().slice(0, 13))
  }

  if (timestamp.toString().length < 13) {
    return parseInt(
      `${timestamp}${'0'.repeat(13 - timestamp.toString().length)}`
    )
  }

  return timestamp
}

export const getUTCDateFromTimestamp = (timestamp: number) => {
  const date = new Date(convertTimestampToMilliseconds(timestamp))

  return `${date.getUTCDate()}-${
    date.getUTCMonth() + 1
  }-${date.getUTCFullYear()}`
}

export const getProposalUrl = (proposalId: number): string => {
  return `${ENDPOINTS.rest}/cosmos/gov/v1/proposals/${proposalId.toString()}`
}

export const isInjDomainName = (domainName: string): boolean => {
  const VALID_INJ_DOMAIN_NAME_REGEX = '\\.inj'

  return new RegExp(VALID_INJ_DOMAIN_NAME_REGEX, 'i').test(domainName)
}

export const isInjBonfidaDomainName = (domainName: string): boolean => {
  const VALID_INJ_DOMAIN_NAME_REGEX = '\\.sol'

  return new RegExp(VALID_INJ_DOMAIN_NAME_REGEX, 'i').test(domainName)
}

export const unAbbreviateNumber = (
  value: string
): BigNumberInBase | undefined => {
  const units = {
    K: Number(`1${'0'.repeat(3)}`),
    M: Number(`1${'0'.repeat(6)}`),
    B: Number(`1${'0'.repeat(9)}`),
    T: Number(`1${'0'.repeat(12)}`)
  } as Record<string, number>

  const unit = value.at(-1)

  if (!unit || !units[unit]) {
    return
  }

  const formattedValue = value.replaceAll(',', '').slice(0, -1)

  return new BigNumberInBase(formattedValue).multipliedBy(units[unit])
}

export const abbreviateNumber = (number: number) => {
  const abbreviatedValue = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: 2
  }).format(number)

  const abbreviatedValueMatchesInput = new BigNumberInBase(number).eq(
    unAbbreviateNumber(abbreviatedValue) || '0'
  )

  return abbreviatedValueMatchesInput
    ? abbreviatedValue
    : `≈${abbreviatedValue}`
}

export const promiseAllInBatches = async ({
  fn,
  items,
  limit
}: {
  items: any[]
  limit: number
  fn: (item: any) => Promise<any>
}) => {
  let results = [] as any[]

  for (let start = 0; start < items.length; start += limit) {
    const batch = items.slice(start, limit + start)
    const batchResult = await Promise.all(batch.map(fn))

    results = [...results, ...batchResult]
  }

  return results
}

export const getFactoryTokenDenomFromSymbolOrNameAsString = (
  symbolOrName: string,
  source?: TokenSource
) =>
  tokenFactoryStatic.getMetaBySymbol(symbolOrName, {
    source,
    type: TokenType.TokenFactory
  })?.denom as string

export const getPeggyDenomFromSymbolOrNameAsString = (symbolOrName: string) =>
  tokenFactoryStatic.getMetaBySymbol(symbolOrName, {
    type: TokenType.Erc20
  })?.denom as string

export const getToken = async (
  denomOrSymbol: string
): Promise<TokenStatic | undefined> => {
  const token = tokenFactoryStatic.toToken(denomOrSymbol)

  if (token) {
    return token
  }

  const asyncToken = await sharedTokenClient.queryToken(denomOrSymbol)

  return asyncToken
}

export const checkHighCommisionRate = (validator: ValidatorWithUptimeDetails) =>
  new BigNumberInBase(validator.commissionRate).times(100).isEqualTo(100)

export const checkHighVotingPower = (
  validator: ValidatorWithUptimeDetails,
  totalDelegatedAmount: string
) =>
  new BigNumberInBase(validator.delegatorShares || 0)
    .dividedBy(totalDelegatedAmount)
    .times(100)
    .gt(VOTING_POWER_PERCENTAGE)

export const formatedDistance = (from: Date | number, to: Date | number) => {
  const distance = Math.abs(new Date(from).getTime() - new Date(to).getTime())
  const days = Math.floor(distance / (1000 * 60 * 60 * 24))
  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((distance % (1000 * 60)) / 1000)

  return `${days}d ${hours}h ${minutes}m ${seconds}s`
}

export const getProposalContent = (proposal: UiSingleProposal, key: string) => {
  return proposal?.content?.value?.[key] || ''
}

export const isValidInjAddress = (value: string) => {
  if (!value.startsWith('inj')) {
    return false
  }

  if (value.length !== 42) {
    return false
  }

  return true
}
