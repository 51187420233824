import {
  TokenFactoryDenomField,
  TokenFactorySupplyField,
  TokenFactoryMetadataField
} from '@/types'

export default {
  tokenFactory: {
    title: 'Manage token factory denom',
    decimalPlaces: 'Decimal places',
    currentSupply: 'Current supply',

    mint: {
      title: 'Mint supply',
      cta: 'Mint',
      successToast: 'Supply successfully minted'
    },

    burn: {
      title: 'Burn supply',
      cta: 'Burn',
      successToast: 'Supply successfully burnt'
    },

    createSubDenom: {
      title: 'Create subdenom',
      cta: 'Create',
      successToast: 'Token factory denom successfully created'
    },

    updateTokenMetadata: {
      title: 'Update token metadata',
      cta: 'Update',
      successToast: 'Token factory meta successfully updated'
    },

    card: {
      name: 'Name',
      supply: 'Supply',
      symbol: 'Symbol',
      exponent: 'Exponent',
      admin: 'Admin',
      revokeAdmin: 'Revoke',
      revokeSuccessToast: 'Admin privileges revoked'
    },

    [TokenFactorySupplyField.Amount]: {
      title: 'Amount',
      placeholder: '1000000000'
    },

    [TokenFactoryDenomField.SubDenom]: {
      title: 'Subdenom',
      tooltip: 'subdenom can be up to 44 "alphanumeric" characters long.',
      placeholder: 'Enter token factory subdenom'
    },

    [TokenFactoryMetadataField.URI]: {
      title: 'URI',
      tooltip:
        'URI to a document (on or off-chain) that contains additional information.',
      placeholder: 'URL to the token image'
    },
    [TokenFactoryMetadataField.Name]: {
      title: 'Name',
      tooltip: 'name defines the name of the token (eg: Injective)',
      placeholder: 'Injective'
    },
    [TokenFactoryMetadataField.Symbol]: {
      title: 'Symbol',
      tooltip:
        'symbol is the token symbol usually shown on exchanges (eg: INJ). This can be the same as the display.',
      placeholder: 'INJ'
    },
    [TokenFactoryMetadataField.Display]: {
      title: 'Display',
      tooltip:
        'Display indicates the suggested denom that should be displayed in clients.',
      placeholder: 'INJ'
    },
    [TokenFactoryMetadataField.Exponent]: {
      title: 'Decimal places',
      tooltip:
        "Exponent represents power of 10 exponent that one must raise the base_denom to in order to equal the given DenomUnit's denom 1 denom = 10^exponent base_denom",
      placeholder: '18'
    },
    [TokenFactoryMetadataField.Description]: {
      title: 'Description',
      placeholder: 'Token description'
    }
  }
}
