export enum MainPage {
  Faq = 'faq',
  Wallet = 'wallet',
  WalletTransactions = 'wallet-transactions',
  Index = 'index',
  Stake = 'stake',
  MyStaking = 'stake-my-staking',
  Governance = 'governance',
  Auction = 'auction-auction',
  TokenFactory = 'token-factory',
  Proposal = 'proposal-proposal',
  Validator = 'validators-validator',
  ProposalCreateType = 'proposal-create-type'
}
