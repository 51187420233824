export * from './mixpanel'

export enum Interval {
  All = 'all',
  Day = 'day',
  Week = 'week',
  Year = 'year',
  Month = 'month',
  ThreeMonths = 'three-months'
}

export enum Modal {
  Send = 'Send',
  Terms = 'Terms',
  Unbond = 'unbond',
  QrCode = 'qrcode',
  Redeem = 'redeem',
  DevMode = 'devMode',
  Propose = 'propose',
  Underwrite = 'underwrite',
  AuctionBid = 'auction-bid',
  ReDelegate = 're-delegate',
  GeoRestricted = 'GeoRestricted',
  RedeemToCw20 = 'redeem-to-cw20',
  WalletConnect = 'wallet-connect',
  AuctionTicket = 'auction-ticket',
  TokenSelector = 'token-selector',
  ConvertToBank = 'convert-to-bank',
  StakingRewards = 'staking-rewards',
  ScavengerHunt = 'scavenger-hunt',
  AuctionPoolAdd = 'auction-pool-add',
  CreateSubDenom = 'create-sub-denom',
  AuctionMilestone = 'auction-milestone',
  CurrencySelector = 'currency-selector',
  DelegationWarning = 'delegation-warning',
  RequestRedemption = 'request-redemption',
  DelegateToValidator = 'delegate-to-validator',
  CreateInsuranceFund = 'create-insurance-fund',
  InsufficientInjForGas = 'insufficient-inj-for-gas',
  DecentralizationWarning = 'decentralization-warning',
  MintTokenFactorySupply = 'mint-token-factory-supply',
  BurnTokenFactorySupply = 'burn-token-factory-supply',
  UpdateTokenFactoryMetadata = 'update-token-factory-metadata'
}

export enum AuctionBidState {
  Bid = 'bid',
  Confirm = 'confirm',
  Submitted = 'submitted'
}

export enum PieChart {
  Vote = 'vote',
  Deposit = 'deposit'
}

export enum ProgressBarType {
  Gray = 'gray',
  Accent = 'accent',
  Primary = 'primary',
  Gradient = 'gradient'
}

export enum TableHeaderType {
  // staking validators
  Uptime = 'Uptime',
  Rewards = 'Rewards',
  Validator = 'validator',
  Commission = 'commission',
  VotingPower = 'votingPower',
  StakedAmount = 'stakedAmount',
  CompletionTime = 'completionTime',

  // insurance funds
  Funds = 'funds',
  Market = 'market',
  MyPoolTokens = 'myPoolTokens',
  TotalPoolTokens = 'totalPoolTokens',
  MyOwnershipPercentage = 'myOwnershipPercentage'
}

export enum ProposalType {
  TextProposal = 'text-proposal',
  SpotMarketLaunch = 'spot-market-launch',
  PerpetualMarketLaunch = 'perpetual-market-launch',
  InstantSpotMarketLaunch = 'instant-spot-market-launch'
}

export enum ValidatorUptimeStatus {
  Missed = 'missed',
  Signed = 'signed',
  Proposed = 'proposed'
}

export enum StreamType {
  Bids = 'bids',
  Blocks = 'blocks',
  Transactions = 'transactions'
}

export enum AmplitudeEvents {
  Login = 'Login',
  Claim = 'Claim',
  Delegate = 'Delegate',
  Redelegate = 'Re-delegate',
  Unbond = 'Unbond',
  Vote = 'Vote',
  PlaceBid = 'Place-bid',
  ClaimAll = 'Claim All',
  ConnectClicked = 'Connect Clicked',
  WalletSelected = 'Wallet Selected',
  KadoBannerClicked = 'Kado Banner Clicked'
}

export enum EventBus {
  ProposalQuoteChange = 'ProposalQuoteChange',
  Delegate = 'Delegate',
  Redelegate = 'Re-delegate',
  Unbond = 'Unbond'
}

export enum ValidatorStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export enum StakingStatus {
  Unbonding = 'unbonding',
  Redelegation = 'redelegation'
}

export enum WalletModalType {
  All = 'all',
  Ledger = 'ledger',
  Trezor = 'trezor'
}

export enum Theme {
  Dark = 'dark',
  Light = 'light'
}

export enum MenuItemType {
  Link = 'link',
  Dropdown = 'dropdown'
}

export enum Pages {
  Home = 'index',
  Wallet = 'wallet',
  Staking = 'stake',
  Auction = 'auction-auction',
  Governance = 'governance',
  MyStaking = 'stake-my-staking',
  WalletTransactions = 'wallet-transactions'
}

export enum StakingSortType {
  Validator = 'validator',
  Uptime = 'uptime',
  Commision = 'commission',
  VotingPower = 'votingPower',
  StakedAmount = 'stakedAmount'
}

export enum StakingModalView {
  Amount = 'amount',
  Confirm = 'confirm',
  Success = 'success',
  Validator = 'validator',
  CommissionWarning = 'commission-warning',
  DecentralizationWarning = 'decentralization-warning'
}

export enum StatusKey {
  ProposalSubmitStatus = 'ProposalSubmitStatus'
}

export enum DappCategory {
  Dex = 'DEX',
  DeFi = 'DeFi',
  Tools = 'Tools',
  NFTFi = 'NFTFi',
  SocialFi = 'SocialFi',
  AllCategory = 'All Category'
}
