<script lang="ts" setup>
import { twMerge } from 'tailwind-merge'
import { cva, type VariantProps } from 'class-variance-authority'

const inputClasses = cva(
  [
    'flex',
    'gap-2',
    'w-full',
    'border',
    'rounded',
    'item-center',
    'items-center',
    'input-wrapper',
    'font-semibold'
  ],
  {
    variants: {
      intent: {
        solid: 'border-borderGray focus-within:border-gray-300',
        transparent: 'border-transparent'
      },
      size: {
        xs: '',
        sm: '',
        md: 'py-3 px-4 text-sm',
        lg: 'py-3 px-6 text-base'
      }
    }
  }
)

type InputProps = VariantProps<typeof inputClasses>

const props = withDefaults(
  defineProps<{
    type?: 'input' | 'textarea' | 'numeric'
    size?: InputProps['size']
    class?: string
    intent?: 'solid' | 'transparent'
    isDisabled?: boolean
  }>(),
  {
    type: 'input',
    size: 'md',
    class: '',
    intent: 'solid'
  }
)
</script>
<script lang="ts">
export default {
  inheritAttrs: false
}
</script>

<template>
  <div
    :class="[
      twMerge(inputClasses({ size, intent, class: props.class })),
      ,
      {
        'cursor-not-allowed bg-gray-300/20': isDisabled
      }
    ]"
  >
    <SharedTextArea
      v-if="type === 'textarea'"
      v-bind="{ ...$attrs, disabled: isDisabled }"
    />

    <template v-else>
      <slot name="prefix" />

      <SharedInput
        v-if="type === 'input'"
        v-bind="{ ...$attrs, disabled: isDisabled }"
      />
      <SharedNumInput v-else v-bind="{ ...$attrs, disabled: isDisabled }" />

      <slot name="suffix" />
    </template>
  </div>
</template>

<style lang="css">
input::placeholder,
textarea::placeholder {
  @apply text-placeholderGray;
}

input:focus {
  @apply outline-none;
}
</style>
