export default defineAppConfig({
  ui: {
    primary: 'brand',
    gray: 'slate',

    button: {
      variant: {
        solid: 'dark:text-white'
      }
    },

    badge: {
      variant: {
        solid: 'text-white dark:text-white'
      }
    },

    tooltip: {
      base: '[@media(pointer:coarse)]:hidden px-2 py-1 text-xs h-auto font-normal relative overflow-auto text-wrap'
    },

    input: {
      default: {
        size: 'lg'
      }
    }
  }
})
