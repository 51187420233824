import { defineStore } from 'pinia'
import { fetchCoinStats, fetchChartData } from '@/app/services/stats'
import { Currency, UiCoinStats, UiMarketChart } from '@/types'

type StatsStoreState = {
  uiCoinStats: UiCoinStats
  uiMarketChart: UiMarketChart
}

const initialStateFactory = (): StatsStoreState => ({
  uiCoinStats: {} as UiCoinStats,
  uiMarketChart: {
    prices: [],
    totalVolumes: []
  } as unknown as UiMarketChart
})

export const useStatsStore = defineStore('stats', {
  state: (): StatsStoreState => initialStateFactory(),
  getters: {
    price: (state) =>
      state.uiCoinStats?.currentPrice &&
      state.uiCoinStats.currentPrice[Currency.Usd]
        ? state.uiCoinStats.currentPrice[Currency.Usd]
        : 0,
    volume24: (state) => state.uiCoinStats.totalVolume[Currency.Usd]
  },
  actions: {
    async fetchCoinStats() {
      const statsStore = useStatsStore()

      statsStore.$patch({
        uiCoinStats: await fetchCoinStats()
      })
    },

    async fetchChartData({ from, to }: { from: number; to: number }) {
      const appStore = useAppStore()
      const statsStore = useStatsStore()

      const { currency } = appStore

      statsStore.$patch({
        uiMarketChart: await fetchChartData({
          currency: currency.code,
          from,
          to
        })
      })
    },

    reset() {
      useStatsStore().$reset()
    }
  }
})
