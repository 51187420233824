export default {
  home: {
    step1: {
      title: 'Get a wallet',
      description:
        'An Injective Wallet allows you to  effortlessly oversee and utilize both native tokens on the Injective platform and bridged assets sourced from supported networks, with full ownership.'
    },
    step2: {
      title: 'Get INJ',
      description:
        'INJ is the native utility token that powers Injective and its rapidly growing ecosystem. You can trade to get INJ from the below exchanges. Please always be cautious and do your own research before.',
      centralized: 'Centralized',
      decentralized: 'Decentralized',
      getInj: 'Get INJ with fiat currencies'
    },
    step3: {
      title: 'Stake INJ',
      description:
        'Participate in various activities such as approving proposals for protocol upgrades, staking for rewards, participating in burn auctions, and more, all within the Injective blockchain network.',
      title2: 'What is Injective Staking?',
      description2:
        'Injective staking is the act of delegating your INJ tokens to contribute to the validation of transactions on the Injective blockchain. In exchange, you earn rewards which start to accrue as soon as you begin staking. The reward rate of the network is currently 19.14 %.',
      learn: 'Learn about INJ staking',
      enterStakingAmount: 'Enter staking amount',
      estimatedRewards: 'Estimated rewards',
      stakingPeriod: 'Staking period',
      day: 'Day',
      days: 'Days'
    },
    step4: {
      title: 'Explore Dapps',
      description:
        'Injective has built the largest and fastest growing Web3 financial ecosystem. Explore the global network of dApps, validators, builders and partners working together to create a truly free and inclusive financial world.'
    }
  }
}
