import { defineRule } from 'vee-validate'
import { BigNumberInBase } from '@injectivelabs/utils'
import { INJ_FEE_BUFFER, NUMBER_REGEX } from '@/app/utils/constant'
import { isInjBonfidaDomainName, isInjDomainName } from '~/app/utils/helpers'

export const errorMessages = {
  required: () => 'This field is required',
  between: (min: string, max: string) =>
    `The value must be between ${min} and ${max}`,
  urlFormat: () => 'Invalid url format',
  email: () => 'This field should be a valid email',
  minValue: (min: string) => `This field should be greater than ${min}`,
  positiveNumber: () => 'Invalid value',
  injaddress: () => `This field is not a valid Injective address`,
  injName: () => 'This field is not a valid Injective name',
  injBonfidaName: () => 'This field is not a valid Injective name',
  ethAddress: () => `This field is not a valid Ethereum address`,
  double: () => 'This field is a decimal number',
  maxChar: (allowedLength: string) =>
    `This field cannot be more than ${allowedLength} characters`,
  powerOfTen: (type: string) => `Invalid ${type} value`,
  auctionMin: (min: string) => `Your bid cannot be lower than ${min} INJ`,
  auctionMax: () => 'Your bid cannot be higher than your balance - INJ fee',
  hasNoBalance: () =>
    'Please enter an amount no higher than your available balance.',
  balanceGreaterThanZero: () => 'Insufficient Balance',
  maximumAmount: (value: string) => `Value cannot be larger than ${value}`,
  minimumAmount: (value: string) => `Amount must be bigger than ${value}`,
  insufficientFunds: () => `Insufficient Funds`,
  aboveMaxMinusGasBuffer: (gasBuffer: string) =>
    `Insufficient Balance. Gas fees are ${gasBuffer}INJ.`,
  fixedLength: (length: string) =>
    `This field must be exactly ${length} characters long.`
} as Record<string, any>

export const defineGlobalRules = () => {
  defineRule('required', (value: string) => {
    if (!value || !String(value).trim().length) {
      return errorMessages.required()
    }

    return true
  })

  defineRule('minValue', (value: string, [min]: string[]) => {
    if (Number(value) < Number(min)) {
      return errorMessages.minValue(min)
    }

    return true
  })

  defineRule('url', (value: string) => {
    const re =
      /^(http(s):\/\/.)[-a-zA-Z0-9@:%.+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%+.~#?&//=]*)$/g

    if (value.trim() === '') {
      return true
    }

    if (!re.test(String(value))) {
      return errorMessages.urlFormat()
    }

    return true
  })

  defineRule('email', (value: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (!re.test(String(value))) {
      return errorMessages.email()
    }

    return true
  })

  defineRule('between', (value: string, [min, max]: string[]) => {
    if (Number(min) > Number(value) || Number(max) < Number(value)) {
      return errorMessages.between(min, max)
    }

    return true
  })

  defineRule('max', (value: string, [max]: string[]) => {
    if (value.length > Number(max)) {
      return errorMessages.maxChar(max)
    }

    return true
  })

  defineRule('maxAmount', (value: string, [max]: string[]) => {
    if (new BigNumberInBase(value).gt(max)) {
      return errorMessages.maximumAmount(max)
    }

    return true
  })

  defineRule('noBalance', (value: string, [max]: string[]) => {
    if (Number(max) === 0) {
      return errorMessages.balanceGreaterThanZero()
    }

    if (Number(value) > Number(max)) {
      return errorMessages.hasNoBalance()
    }

    return true
  })

  defineRule('injAddress', (value: string) => {
    if (!value.startsWith('inj')) {
      return errorMessages.injaddress()
    }

    if (value.length !== 42) {
      return errorMessages.injaddress()
    }

    return true
  })

  defineRule('injOrNameService', (value: string) => {
    const isInjName = isInjDomainName(value)
    const isInjBonfidaName = isInjBonfidaDomainName(value)
    const isInjAddress = value.startsWith('inj') && value.length === 42

    if (isInjName || isInjBonfidaName || isInjAddress) {
      return true
    }

    return errorMessages.injaddress()
  })

  defineRule('injName', (value: string) => {
    if (value.includes(' .') || value.includes('. ')) {
      return errorMessages.injName(value)
    }

    const [prefix, suffix] = value.split('.')

    if (prefix.length <= 2 || !suffix?.trim().toLowerCase().endsWith('inj')) {
      return errorMessages.injName(value)
    }

    return true
  })

  defineRule('injBonfidaName', (value: string) => {
    if (value.includes(' .') || value.includes('. ')) {
      return errorMessages.injBonfidaName(value)
    }

    const [prefix, suffix] = value.split('.')

    if (prefix.length <= 2 || !suffix?.trim().toLowerCase().endsWith('sol')) {
      return errorMessages.injBonfidaName(value)
    }

    return true
  })

  defineRule('ethAddress', (value: string) => {
    if (!value.startsWith('0x') || value.length !== 42) {
      return errorMessages.ethAddress()
    }

    return true
  })

  defineRule('auctionMax', (value: string, [max]: string[]) => {
    if (Number(value) > Number(max)) {
      return errorMessages.auctionMax()
    }

    return true
  })

  defineRule('auctionMin', (value: string, [min]: string[]) => {
    if (Number(value) <= Number(min)) {
      return errorMessages.auctionMin(min)
    }

    return true
  })

  defineRule('positiveNumber', (value: string) => {
    if (!NUMBER_REGEX.test(value)) {
      return errorMessages.positiveNumber()
    }

    return true
  })

  defineRule('powerOfTen', (value: string, [type]: string[]) => {
    const multiplier = Math.pow(10, value.toString().replace('0.', '').length)

    if (Number(value) * multiplier !== 1 && Number(value) * 10 !== multiplier) {
      return errorMessages.powerOfTen(type)
    }

    return true
  })

  defineRule('maxChar', (value: string, [allowedLength]: string[]) => {
    if (value.length > Number(allowedLength)) {
      return errorMessages.maxChar(allowedLength)
    }

    return true
  })

  defineRule('double', (value: string) => {
    if (!value.includes('.')) {
      return errorMessages.double()
    }

    return true
  })

  defineRule(
    'betweenRangeAndBalance',
    (value: string, [min, max, balance]: string[]) => {
      const valueInBigNumber = new BigNumberInBase(value)
      const balanceInBigNumber = new BigNumberInBase(balance)

      if (valueInBigNumber.lt(min)) {
        return errorMessages.minimumAmount(min)
      }

      if (valueInBigNumber.gt(balanceInBigNumber)) {
        return errorMessages.insufficientFunds()
      }

      if (valueInBigNumber.gt(max)) {
        return errorMessages.maximumAmount(max)
      }

      return true
    }
  )

  defineRule('injBalance', (value: string, [min, max]: string[]) => {
    const valueInBigNumber = new BigNumberInBase(value)
    const maxMinusGasBuffer = new BigNumberInBase(max).minus(INJ_FEE_BUFFER)

    if (valueInBigNumber.lt(min)) {
      return errorMessages.minimumAmount(min)
    }

    if (valueInBigNumber.gt(maxMinusGasBuffer) && valueInBigNumber.lt(max)) {
      return errorMessages.aboveMaxMinusGasBuffer(INJ_FEE_BUFFER)
    }

    if (valueInBigNumber.gt(max)) {
      return errorMessages.insufficientFunds()
    }

    return true
  })

  defineRule('maxCharacters', (value: string, [max]: string[]) => {
    if (value.length > Number(max)) {
      return errorMessages.maxChar(max)
    }
    return true
  })

  defineRule('fixedCharacters', (value: string, [length]: string[]) => {
    if (value.length !== Number(length)) {
      return errorMessages.fixedLength(length)
    }
    return true
  })
}

export default defineNuxtPlugin(() => {
  defineGlobalRules()
})
