import { Status } from '@injectivelabs/utils'

type StatusMap = Record<string, Status>

const statusMap = reactive<StatusMap>({} as StatusMap)

export function useStatusMap() {
  const provideStatuses = (statuses: StatusMap) => {
    Object.assign(statusMap, statuses)
  }

  return {
    statusMap,
    provideStatuses
  }
}
