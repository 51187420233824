import {
  CoinGeckoCoinResponse,
  CoinGeckoMarketChartResponse
} from '@shared/types'
import { UiCoinStats, UiMarketChart } from '@/types/stats'

export class UiStatsTransformer {
  static getCurrencyBasedObject(stats: any) {
    return {
      chf: stats ? stats.chf || 0 : 0,
      eur: stats ? stats.eur || 0 : 0,
      gbp: stats ? stats.gbp || 0 : 0,
      usd: stats ? stats.usd || 0 : 0
    }
  }

  static coinStatsToUiCoinStats(stats: CoinGeckoCoinResponse): UiCoinStats {
    const { market_data: marketData } = stats

    return {
      id: stats.id,
      symbol: stats.symbol,
      name: stats.name,
      currentPrice: UiStatsTransformer.getCurrencyBasedObject(
        marketData.current_price
      ),
      totalVolume: UiStatsTransformer.getCurrencyBasedObject(
        marketData.total_volume
      ),
      high24h: UiStatsTransformer.getCurrencyBasedObject(marketData.high_24h),
      marketCap: UiStatsTransformer.getCurrencyBasedObject(
        marketData.market_cap
      ),
      priceChangePercentage24h: marketData.price_change_percentage_24h,
      marketCapChange24h: marketData.market_cap_change_percentage_24h
    }
  }

  static blankCoinStatsToUiCoinStats(): UiCoinStats {
    const getCurrencyBasedObject = () => {
      return {
        chf: 0,
        eur: 0,
        gbp: 0,
        usd: 0
      }
    }

    return {
      id: '',
      symbol: '',
      name: '',
      currentPrice: getCurrencyBasedObject(),
      totalVolume: getCurrencyBasedObject(),
      high24h: getCurrencyBasedObject(),
      marketCap: getCurrencyBasedObject(),
      priceChangePercentage24h: 0,
      marketCapChange24h: 0
    }
  }

  static chartDataToUiChartData(
    stats: CoinGeckoMarketChartResponse
  ): UiMarketChart {
    return {
      prices: stats.prices,
      totalVolumes: stats.total_volumes
    }
  }

  static blankChartDataToUiChartData(): UiMarketChart {
    return {
      prices: [],
      totalVolumes: []
    }
  }
}
