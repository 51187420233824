export default {
  transaction: {
    txHash: 'Tx Hash',
    result: 'Result',
    reset: 'Reset',
    block: 'Block',
    failed: 'Failed',
    success: 'Success',
    eventType: 'Event Type',
    selectAll: 'Select All'
  }
}
