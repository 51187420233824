import { Wallet } from '@injectivelabs/wallet-ts'
import { mixpanelAnalytics } from '@/app/providers/mixpanel/BaseTracker'
import { MixpanelCounter, MixpanelEvent } from '@/types'

export const trackConnectClicked = () => {
  const client = mixpanelAnalytics.getMixpanelClient()

  if (!client) {
    return
  }

  mixpanelAnalytics.track(MixpanelEvent.ConnectClicked, {})
  mixpanelAnalytics.increment(MixpanelCounter.ConnectClicked)
}

export const trackLogin = ({
  address,
  wallet
}: {
  address: string
  wallet: Wallet
}) => {
  const client = mixpanelAnalytics.getMixpanelClient()

  if (!client) {
    return
  }

  client.identify(address)

  mixpanelAnalytics.track(MixpanelEvent.Login, {
    wallet,
    address
  })

  client.people.increment({ Login: 1, [wallet]: 1 })
  client.people.set({ wallet })
}

export const trackWalletAddress = (injectiveAddress: string) => {
  const client = mixpanelAnalytics.getMixpanelClient()

  if (!client) {
    return
  }

  client.identify(injectiveAddress)
}

export const trackLogout = () => {
  const client = mixpanelAnalytics.getMixpanelClient()

  if (!client) {
    return
  }

  client.reset()
}
