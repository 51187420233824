import { BigNumberInBase } from '@injectivelabs/utils'
import { coinGeckoApi } from '@shared/Service'
import { injToken } from '@shared/data/token'
import { UiStatsTransformer } from '@/app/client/transformers/UiStatsTransformer'
import { Currency } from '@/types/currency'

// todo: kill this when we remove support for currency filtering
export const fetchCoinStats = async (coinId = injToken.coinGeckoId) => {
  const stats = await coinGeckoApi.fetchCoin(coinId)

  if (!stats) {
    return UiStatsTransformer.blankCoinStatsToUiCoinStats()
  }

  return UiStatsTransformer.coinStatsToUiCoinStats(stats)
}

export const fetchChartData = async ({
  coinId = 'injective-protocol',
  from,
  to,
  currency
}: {
  coinId?: string
  currency: Currency
  from: number
  to: number
}) => {
  try {
    const chartData = await coinGeckoApi.fetchChart(coinId, {
      currency,
      from: new BigNumberInBase(
        new BigNumberInBase(from).toFixed(0)
      ).toNumber(),
      to: new BigNumberInBase(new BigNumberInBase(to).toFixed(0)).toNumber(),
      vs_currency: currency
    })

    if (!chartData) {
      return UiStatsTransformer.blankChartDataToUiChartData()
    }

    return UiStatsTransformer.chartDataToUiChartData(chartData)
  } catch (e) {
    // throw silently for now
  }
}
